import { getList } from '@/requests/api/get-list';
import { type BrfCompany, type ExternalClient } from '@pigello/pigello-matrix';
import { chunkIds } from '../utils';
import type { TCustomList } from './types';

export async function brfCompanyNestedCase(
  listResponse: TCustomList<BrfCompany>
): Promise<TCustomList<BrfCompany>> {
  const sourceObjectIds = listResponse.data.reduce<Set<string>>(
    (acc, brfCompany) => {
      if (brfCompany.company?.managedBy?.id) {
        acc.add(brfCompany.company.managedBy.id);
      }
      return acc;
    },
    new Set()
  );

  if (sourceObjectIds.size === 0) return listResponse;

  const chunkedSourceIds = chunkIds(sourceObjectIds);
  const resolvedSourcesPromises = await Promise.allSettled(
    chunkedSourceIds.map((chunk) =>
      getList<ExternalClient>({
        modelName: 'externalclient',
        queryParams: {
          page: 1,
          pageSize: chunk.length,
          filters: {
            id: {
              __in: chunk.join(','),
            },
          },
        },
      })
    )
  );

  const resolvedSourceObjects: ExternalClient[] = [];
  const failedSourceObjects: unknown[] = [];
  for (const promise of resolvedSourcesPromises) {
    if (promise.status === 'fulfilled') {
      resolvedSourceObjects.push(...(promise.value.list ?? []));
    } else {
      failedSourceObjects.push(promise.reason);
    }
  }

  return {
    ...listResponse,
    data: listResponse.data.map((brfCompany) => {
      const sourceExternalClientId = brfCompany.company?.managedBy?.id;
      if (!sourceExternalClientId || !brfCompany.company?.managedBy?.id) {
        return brfCompany;
      }

      const sourceExternalClient = resolvedSourceObjects.find(
        (externalClient) => externalClient.id === sourceExternalClientId
      );
      if (!sourceExternalClient) return brfCompany;

      return {
        ...brfCompany,
        company: {
          ...brfCompany.company,
          managedBy: sourceExternalClient,
        },
      };
    }),
  };
}
