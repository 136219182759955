import { getConfig } from '@/lib/get-config';
import { type BaseInstance, type ModelName } from '@pigello/pigello-matrix';
import type { UseQueryResult } from '@tanstack/react-query';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { getList } from '../api/get-list';
import { NEVER_FETCH_NESTED_FIELDS, QUERY_KEYS } from '../constants';
import type {
  ErrorResponse,
  ListResponse,
  useGetGenericListProps,
} from '../types';
import { customCaseHandlers } from './custom-cases';
import {
  aggregateNestedData,
  chunkArray,
  extractNestedIds,
  mergeDataWithNested,
} from './utils';

interface UseGetListOptions<TInstance extends BaseInstance>
  extends useGetGenericListProps<TInstance> {
  modelName: ModelName;
}

/**
 * Custom hook to fetch a list of items along with their nested relations.
 *
 * @template TInstance - The type of the base instance.
 * @param {UseGetListOptions<TInstance>} options - The options for fetching the list.
 * @returns {UseQueryResult<ListResponse<TInstance>, ErrorResponse>} The result of the query.
 */
export const useGetList = <TInstance extends BaseInstance>(
  options: UseGetListOptions<TInstance>
): UseQueryResult<ListResponse<TInstance>, ErrorResponse> => {
  const {
    modelName,
    nested = [],
    fetchAllManyRelations = false,
    queryParams,
    overrideUrl,
    overrideUrlReplaceAll,
    waitForAllNested,
    ...queryOptions
  } = options;
  /**
   * Fetch configuration based on the provided model name.
   */
  const config = getConfig<ModelName, TInstance>(modelName ?? null, true);
  /**
   * Filter out fields that should never be fetched to avoid unnecessary data fetching.
   */
  const filteredNested = nested.filter(
    (fieldName) => !NEVER_FETCH_NESTED_FIELDS.find((f) => f === fieldName)
  );

  /**
   * React Query to fetch the main list based on the generated query keys.
   */
  const mainQuery = useQuery({
    queryKey: [
      modelName,
      QUERY_KEYS.LIST,
      queryParams,
      overrideUrl,
      overrideUrlReplaceAll,
    ],
    queryFn: async ({ signal }: { signal: AbortSignal }) => {
      const res = await getList<TInstance>({
        modelName,
        queryParams,
        overrideUrl,
        overrideUrlReplaceAll,
        signal,
      });
      return res;
    },
    ...queryOptions,
  });
  /**
   * Extract nested IDs from the main list to identify which nested relations need to be fetched.
   */
  const nestedIds = extractNestedIds(
    mainQuery.data?.list,
    filteredNested,
    fetchAllManyRelations
  );

  /**
   * Prepare the list of nested queries to be fetched based on the extracted IDs.
   */

  const nestedToFetch = Array.from(nestedIds).map(([fieldName, idsList]) => {
    const fieldConfig = config?.fields[fieldName];
    // Ensure the field is a valid relation field.
    if (!fieldConfig || !('relationConfig' in fieldConfig)) {
      throw new Error(
        `Field ${String(fieldName)} is not a relation field in model ${modelName}`
      );
    }
    const relationModelName = fieldConfig.relationConfig;
    const batchedIds = chunkArray(idsList, 40);
    if (modelName.endsWith('monitoring') && fieldName === 'mtParentInstance') {
      return {
        fieldName,
        relationModelName: config.parentConfig as ModelName,
        batchedIds,
      };
    }
    return {
      fieldName,
      relationModelName,
      batchedIds,
    };
  });

  /**
   * Use React Query's useQueries to fetch all nested relations in parallel.
   */
  const nestedQueries = useQueries({
    queries: nestedToFetch.flatMap(
      ({ fieldName, relationModelName, batchedIds }) => {
        const batchedQueries = [];
        for (const [index, batch] of batchedIds.entries()) {
          batchedQueries.push({
            queryKey: [
              'nested',
              relationModelName,
              fieldName,
              index,
              batch.join(','),
            ],
            queryFn: async () => {
              const { list } = await getList<TInstance>({
                modelName: relationModelName,
                queryParams: {
                  filters: {
                    id: { __in: batch.join(',') },
                  },
                  pageSize: batch.length,
                },
              });
              return { fieldName, data: list };
            },
            enabled: batch.length > 0,
          });
        }

        return batchedQueries;
      }
    ),
  });

  /**
   * Aggregate the fetched nested data into a map for efficient access during merging.
   */
  const aggregatedNestedData = aggregateNestedData(nestedQueries);

  /**
   * Merge the aggregated nested data with the main list data.
   */

  const mergedData = useMemo(() => {
    if (!mainQuery.data || !aggregatedNestedData.size) return mainQuery.data;
    return mergeDataWithNested(
      mainQuery.data,
      aggregatedNestedData,
      filteredNested
    );
  }, [mainQuery.data, aggregatedNestedData, filteredNested]);
  /**
   * Apply custom case handlers if available for the given model.
   */
  const customizedDataResult = useQuery({
    queryKey: ['customizedData', modelName, JSON.stringify(mergedData)],
    queryFn: async () => {
      const handler = customCaseHandlers[modelName];
      if (!handler) {
        return mergedData;
      }
      try {
        const processedList = await handler({ data: mergedData?.list ?? [] });
        return {
          list: processedList.data,
          meta: mergedData?.meta,
        } as ListResponse<TInstance>;
      } catch {
        return mergedData;
      }
    },
    enabled: !!mergedData?.list.length && !!customCaseHandlers[modelName],
  });

  /**
   * Determine if there is a custom case handler for the model.
   */
  const hasCustomCaseHandler = !!customCaseHandlers[modelName];

  /**
   * Combine the loading states based on the `waitForAllNested` flag.
   */
  const isLoading = waitForAllNested
    ? mainQuery.isLoading ||
      nestedQueries.some((q) => q.isLoading) ||
      customizedDataResult.isLoading
    : mainQuery.isLoading;
  const isFetching = waitForAllNested
    ? mainQuery.isFetching ||
      nestedQueries.some((q) => q.isFetching) ||
      customizedDataResult.isFetching
    : mainQuery.isFetching;
  const isPending = waitForAllNested
    ? mainQuery.isPending ||
      nestedQueries.some((q) => q.isPending) ||
      customizedDataResult.isPending
    : mainQuery.isPending;
  const isSuccess = waitForAllNested
    ? mainQuery.isSuccess ||
      nestedQueries.every((q) => q.isSuccess) ||
      customizedDataResult.isSuccess
    : mainQuery.isSuccess;

  /**
   * Return the combined query result including merged data and loading states.
   */
  return {
    data: hasCustomCaseHandler ? customizedDataResult.data : mergedData,
    isLoading,
    isFetching,
    isPending,
    isSuccess,
  } as UseQueryResult<ListResponse<TInstance>, ErrorResponse>;
};
