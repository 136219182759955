import { getList } from '@/requests/api/get-list';
import {
  InvoiceConfig,
  type FieldValue,
  type Invoice,
  type Notification,
  type NotificationDistribution,
  type OrganizationUser,
  type Tenant,
} from '@pigello/pigello-matrix';
import { chunkIds } from '../utils';
import type { TCustomList } from './types';
export async function handleCustomNotificationNestedCase(
  listResponse: TCustomList<NotificationDistribution>
): Promise<TCustomList<NotificationDistribution>> {
  const notificationIds: Set<string> = new Set();

  // Extract unique notification IDs
  for (const distribution of listResponse.data) {
    if (distribution.notification) {
      notificationIds.add(distribution.notification.id);
    }
  }

  const chunkedNotificationIds = chunkIds(notificationIds);
  const resolvedNotificationsPromises = await Promise.allSettled(
    chunkedNotificationIds.map((chunk) =>
      getList<Notification>({
        modelName: 'notification',
        queryParams: {
          page: 1,
          pageSize: chunk.length,
          filters: {
            id: {
              __in: chunk.join(','),
            },
          },
          order: ['-createdAt'],
        },
      })
    )
  );

  const resolvedNotifications: Notification[] = [];
  const failedNotifications: unknown[] = [];
  for (const promise of resolvedNotificationsPromises) {
    if (promise.status === 'fulfilled') {
      resolvedNotifications.push(...(promise.value.list ?? []));
    } else {
      failedNotifications.push(promise.reason);
    }
  }

  if (resolvedNotifications.length === 0) {
    return listResponse;
  }

  // Collect related tenant and organization user IDs
  const recipientIds = {
    tenant: new Set<string>(),
    organizationUser: new Set<string>(),
    subTenant: new Set<string>(),
  };

  for (const notification of resolvedNotifications) {
    if (notification.sentToTenant?.id) {
      recipientIds.tenant.add(notification.sentToTenant.id);
    }
    if (notification.sentToOrganizationUser?.id) {
      recipientIds.organizationUser.add(notification.sentToOrganizationUser.id);
    }
    if (notification.sentToSubTenant?.id) {
      recipientIds.subTenant.add(notification.sentToSubTenant.id);
    }
  }

  // Fetch Tenants
  const chunkedTenantIds = chunkIds(recipientIds.tenant);
  const resolvedTenantsPromises = await Promise.allSettled(
    chunkedTenantIds.map((chunk) =>
      getList<Tenant>({
        modelName: 'tenant',
        queryParams: {
          page: 1,
          pageSize: chunk.length,
          filters: {
            id: {
              __in: chunk.join(','),
            },
          },
          order: ['-createdAt'],
        },
      })
    )
  );

  const resolvedTenants: Tenant[] = [];
  const failedTenants: unknown[] = [];
  for (const promise of resolvedTenantsPromises) {
    if (promise.status === 'fulfilled') {
      resolvedTenants.push(...(promise.value.list ?? []));
    } else {
      failedTenants.push(promise.reason);
    }
  }

  // Fetch Organization Users
  const chunkedOrganizationUserIds = chunkIds(recipientIds.organizationUser);
  const resolvedOrganizationUsersPromises = await Promise.allSettled(
    chunkedOrganizationUserIds.map((chunk) =>
      getList<OrganizationUser>({
        modelName: 'organizationuser',
        queryParams: {
          page: 1,
          pageSize: chunk.length,
          filters: {
            id: {
              __in: chunk.join(','),
            },
          },
          order: ['-createdAt'],
        },
      })
    )
  );

  const resolvedOrganizationUsers: OrganizationUser[] = [];
  const failedOrganizationUsers: unknown[] = [];
  for (const promise of resolvedOrganizationUsersPromises) {
    if (promise.status === 'fulfilled') {
      resolvedOrganizationUsers.push(...(promise.value.list ?? []));
    } else {
      failedOrganizationUsers.push(promise.reason);
    }
  }

  // Fetch Invoices based on notifications
  const invoiceIds: Set<string> = new Set();
  for (const notification of resolvedNotifications) {
    if (
      notification.contentType?.id?.includes(InvoiceConfig.contentType) &&
      notification.objectId
    ) {
      invoiceIds.add(notification.objectId);
    }
  }

  const chunkedInvoiceIds = chunkIds(invoiceIds);
  const resolvedInvoicesPromises = await Promise.allSettled(
    chunkedInvoiceIds.map((chunk) =>
      getList<Invoice>({
        modelName: 'invoice',
        queryParams: {
          filters: {
            id: {
              __in: chunk.join(','),
            },
          },
          page: 1,
          pageSize: chunk.length,
        },
      })
    )
  );

  const resolvedInvoices: Invoice[] = [];
  const failedInvoices: unknown[] = [];
  for (const promise of resolvedInvoicesPromises) {
    if (promise.status === 'fulfilled') {
      resolvedInvoices.push(...(promise.value.list ?? []));
    } else {
      failedInvoices.push(promise.reason);
    }
  }

  // Map fetched data back to the listResponse
  const mappedListResponse: TCustomList<NotificationDistribution> = {
    data: listResponse.data.map((distribution) => {
      const notification = resolvedNotifications.find(
        (n) => n.id === distribution.notification?.id
      );

      let invoiceOcrNumber: FieldValue<string>;
      if (notification?.objectId) {
        const invoice = resolvedInvoices.find(
          (inv) => inv.id === notification.objectId
        );
        invoiceOcrNumber = invoice?.alternativeOcrNumber ?? invoice?.ocrNumber;
      }

      const updatedNotification = {
        ...notification,
        sentToTenant: resolvedTenants.find(
          (tenant) => tenant.id === notification?.sentToTenant?.id
        ),
        sentToOrganizationUser: resolvedOrganizationUsers.find(
          (user) => user.id === notification?.sentToOrganizationUser?.id
        ),
      };

      return {
        ...distribution,
        __ocrNumber: invoiceOcrNumber,
        notification: updatedNotification || distribution.notification,
      };
    }) as NotificationDistribution[],
  };

  return mappedListResponse;
}
